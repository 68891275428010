// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"

import "./src/base"
import "./src/coupons"
import "./src/home"
import "./src/menu"
import "./src/smoothscroll"
import "./src/book"
import "./src/scrollspy"
import "./src/syntax"

// Stimulus.js
import "./controllers"

import Alpine from "alpinejs"

window.Alpine = Alpine

document.addEventListener("turbo:load", () => {
  Alpine.start()
})
