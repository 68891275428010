document.addEventListener('turbo:load', () => {
  const Menu = {
    menuPrimary: document.querySelector('[data-menu="primary"]'),
    menuPrimaryToggleTarget: document.querySelector('[data-menu-target="primary"]'),
    menuBook:document.querySelector('[data-menu=book]'),
    menuBookToggleTarget: document.querySelector('[data-menu-target=book]'),

    init() {
      this.menuToggle();
      this.bookMenuActiveClass();
    },

    menuToggle() {
      this.toggler(this.menuPrimary, this.menuPrimaryToggleTarget);
      this.toggler(this.menuBook, this.menuBookToggleTarget);
    },

    toggler(menu, menuTarget) {
      if (menuTarget) {
        menuTarget.addEventListener('click', () => {
          menu.classList.toggle('hidden');
        })
      }
    },
    bookMenuActiveClass() {
      let path = window.location.pathname;
      let hash = window.location.hash;
      let link = document.querySelector(`a[href='${path + hash}']`);

      if(link) {
        link.classList.add('active')
      }
    }
  }

  Menu.init();
});
