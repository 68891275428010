document.addEventListener('turbo:load', () => {
  const bookMenu = document.querySelector('.book-nav');
  const anchors = document.querySelectorAll('.chapter-link');

  if (typeof(Storage) !== 'undefined' && anchors != null) {
    let lastYPos = +localStorage.getItem('scrollYPos');

    if (lastYPos) {
      bookMenu.scrollTo(0, lastYPos);
    }

    const onNavClick = function() {
      localStorage.setItem('scrollYPos', bookMenu.scrollTop);
    };

    anchors.forEach((anchor)=> {
      anchor.addEventListener("click", onNavClick)
    })
  }
});
