document.addEventListener("turbo:load",() => {
  var modalToggles=document.querySelectorAll('.faqs a[href="#template"]');

  document.addEventListener('click',function(event) {
    if(event.target&&event.target.classList.contains('modal-trigger')) {
      event.preventDefault();
    }
  });

  modalToggles.forEach(function(toggler) {
    toggler.classList.add('modal-trigger');
  });

  const images = document.querySelectorAll('img[data-src]');
  const config = {
    rootMargin: '50px 0px',
    threshold: 0.01
  };

  let observer;

  if ('IntersectionObserver' in window) {
    observer = new IntersectionObserver(onChange, config);
    images.forEach(img => observer.observe(img));
  } else {
    images.forEach(image => loadImage(image));
  }

  function loadImage(image) {
    image.classList.add('fadeIn');
    image.src = image.dataset.src;
  }

  function onChange(changes, observer) {
    changes.forEach(change => {
      if (change.intersectionRatio > 0) {
        loadImage(change.target);
        observer.unobserve(change.target);
      }
    });
  }
});
