import axios from "axios";

document.addEventListener("turbo:load", () => {
  window.Coupon = {};
  Coupon = {
    couponField: document.querySelector("#couponCode"),
    csrfToken: document.querySelector("meta[name=csrf-token]").content,
    invalidIcon: document.querySelector(".js-code-invalid-icon"),
    validIcon: document.querySelector(".js-code-valid-icon"),

    init: function() {
      axios.defaults.headers.common["X-CSRF-Token"] = this.csrfToken;
      this.performCheck();
    },

    performCheck: function() {
      if (this.couponField) {
        this.couponField.addEventListener("keyup", this.searchCodes);
      }
    },

    checkIfEmpty: function(event) {
      const self = this;
      if (event.target.value == "") {
        event.target.className = "input js-coupon-input";
        event.target.classList.remove("code-valid");
        if (self.validIcon && self.invalidIcon) {
          self.validIcon.classList.add("hidden");
          self.invalidIcon.classList.add("hidden");
        }
      }
    },

    couponExists: function(event) {
      const self = this;

      event.target.classList.add("code-valid");
      event.target.classList.remove("code-invalid");
      if (self.validIcon && self.invalidIcon) {
        self.validIcon.classList.remove("hidden");
        self.invalidIcon.classList.add("hidden");
      }
    },

    couponDoesNotExist: function(event) {
      const self = this;

      event.target.classList.remove("code-valid");
      event.target.classList.add("code-invalid");
      if (self.validIcon && self.invalidIcon) {
        self.invalidIcon.classList.remove("hidden");
        self.validIcon.classList.add("hidden");
      }
      self.checkIfEmpty(event);
    },

    checkValidity: function(event) {
      const self = this;

      self.checkIfEmpty(event);
      self.couponExists(event);

      axios({
        method: "GET",
        url: `/checkcoupon?code=${event.target.value}`,
        data: {
          code: `code=${event.target.value}`
        }
      })
        .then(function(response) {
          response.data.coupon_present == true
            ? self.couponExists(event)
            : self.couponDoesNotExist(event);
        })
        .catch(function(error) {
          document.querySelector(".js-form-errors").textContent = error;
        });
    },

    searchCodes: function(event) {
      event.preventDefault();
      Coupon.checkValidity(event);
    }
  };
  Coupon.init();
});
