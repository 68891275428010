import hljs from "highlight.js/lib/highlight"
import bash from "highlight.js/lib/languages/bash"
import css from "highlight.js/lib/languages/css"
import htmlbars from "highlight.js/lib/languages/htmlbars"
import javascript from "highlight.js/lib/languages/javascript"
import ruby from "highlight.js/lib/languages/ruby"
import erb from "highlight.js/lib/languages/erb"
import scss from "highlight.js/lib/languages/scss"
import xml from "highlight.js/lib/languages/xml"

document.addEventListener("turbo:load", (event) => {
  hljs.registerLanguage("bash", bash)
  hljs.registerLanguage("css", css)
  hljs.registerLanguage("htmlbars", htmlbars)
  hljs.registerLanguage("javascript", javascript)
  hljs.registerLanguage("ruby", ruby)
  hljs.registerLanguage("erb", erb)
  hljs.registerLanguage("xml", xml)
  hljs.registerLanguage("scss", scss)

  document.querySelectorAll("pre code").forEach((block) => {
    hljs.highlightBlock(block)
  })
})
