document.addEventListener('turbo:load', () => {

  const Base = {
    init: function() {
      this.smoothScroller("a[href='#get_notified']", "get_notified");
      this.smoothScroller("a[href='#bonus']", "bonus");
      this.smoothScroller("a[href='#teams']", "teams");
      this.formNotify();
      this.toggleGlobal();
    },
    smoothScroller: function(targets, anchor) {
      var t = document.querySelectorAll(targets);
      var a = document.getElementById(anchor);

      if (t != null) {
         for (var i = 0, link = t.length; i < link; i++) {
          t[i].addEventListener('click', function(e) {
            a.scrollIntoView({ behavior: "smooth", block: "start" })
            e.preventDefault();
          });
        }
      }
    },
    formNotify: function() {
      var form = document.querySelector('.get-notified-form');

      if (form) {
        var formSubmit = form.querySelector('input[type=submit]');
        var formInputs = form.querySelectorAll('.input');
      }

      function createErrorBlock() {
        var errorDiv = document.createElement('div');
        errorDiv.textContent = "Those fields can't be empty";
        errorDiv.className += 'ba-gray-light border-radius-sm ta-center text-sm pa10 mt5';
        form.parentNode.insertBefore(errorDiv, form.nextSibling);

        setTimeout(function() {
          formSubmit.removeAttribute('disabled');
          errorDiv.remove();
        }, 3000);
      }

      function enableSubmit(button) {
        button.removeAttribute('disabled');
      }

      if(formSubmit) {
        formSubmit.addEventListener('click', function(e) {
          enableSubmit(this);
          createErrorBlock();
        });
      }

      if (form) {
        form.addEventListener('change', function(e) {
          formSubmit.removeAttribute('disabled');
          formInputs.forEach(function(input) {
            if(input.value.length > 0 ) {
              formSubmit.addEventListener('click', function(e) {
                enableSubmit(this);
                return true;
              });
            }
          });
        });
      }
    },

    toggleGlobal: () => {
      var tog = document.querySelectorAll('[data-toggle]');

      tog.forEach( (t)=> {
        t.addEventListener('click', displayNextSibling)
        var close = t.nextElementSibling.querySelector('[data-toggle-close]')

        if (close) {
          close.addEventListener('click', () => {
            toggleHidden(t.nextElementSibling);
          })
        }
      })

      function toggleHidden(el) {
        el.classList.toggle('hidden');
      }

      function displayNextSibling (event, t) {
        event.preventDefault();
        toggleHidden(event.target.nextElementSibling);
      }
    }
  };

  Base.init();

});
